import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

export interface SeoTypes {
  title?: string;
  titleTemplate?: string;
  description?: string;
  image?: string;
  pathname?: string;
  article?: boolean;
  lang?: string;
}

export interface SiteMetadata {
  site: {
    siteMetadata: {
      defaultTitle: string;
      defaultTitleTemplate: string;
      defaultDescription: string;
      siteUrl: string;
      defaultImage: string;
      twitterUsername: string;
      defaultLang: string;
      fbAppId: string;
    };
  };
}

const Seo: React.FC<SeoTypes> = ({
  title,
  titleTemplate,
  description,
  image,
  pathname,
  article,
  lang,
}: SeoTypes) => {
  const data = useStaticQuery<SiteMetadata>(graphql`
    query {
      site {
        siteMetadata {
          defaultTitle: title
          defaultTitleTemplate: titleTemplate
          defaultDescription: description
          siteUrl: url
          defaultImage: image
          twitterUsername
          defaultLang: lang
          fbAppId: fbAppId
        }
      }
    }
  `);

  const seo: Record<string, string> = {
    title: title || data.site.siteMetadata.defaultTitle,
    description: description || data.site.siteMetadata.defaultDescription,
    image: `${data.site.siteMetadata.siteUrl}${
      image || data.site.siteMetadata.defaultImage
    }`,
    url: `${data.site.siteMetadata.siteUrl}${pathname || '/'}`,
    twitterUsername: data.site.siteMetadata.twitterUsername,
    titleTemplate: titleTemplate || data.site.siteMetadata.defaultTitleTemplate,
    lang: lang || data.site.siteMetadata.defaultLang,
    fbAppId: data.site.siteMetadata.fbAppId || '',
  };

  return (
    <Helmet
      title={seo.title}
      titleTemplate={seo.titleTemplate}
      htmlAttributes={{
        lang: seo.lang,
      }}
    >
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {seo.twitterUsername && (
        <meta name="twitter:creator" content={seo.twitterUsername} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
      <meta property="og:type" content="website" />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.fbAppId && <meta property="fb:app_id" content={seo.fbAppId} />}
    </Helmet>
  );
};

export default Seo;
